import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Button, Modal, useTheme } from '@mui/material';
import moment from 'moment';
moment.updateLocale('en', {
  week: {
    dow: 1,
  }
});

const EmployeeScheduleTable = ({ employeeSchedules }) => {
  // Organize schedules into weeks and days
  const theme = useTheme();
  function getWeekNumber(date) {
    const weekNumber = moment(date).format('WW-YYYY');
    return `W${weekNumber}`;
  }

  // Helper function to get the start date of the week
  function getWeekStartDate(date) {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay());
    return startOfWeek.toISOString().split('T')[0];
  }

  const scheduleTable = {};
  employeeSchedules.forEach((schedule) => {
    const date = new Date(schedule.date);
    const weekNumber = getWeekNumber(date);
    const day = schedule.day;

    if (!scheduleTable[weekNumber]) {
      scheduleTable[weekNumber] = { weekStart: getWeekStartDate(date), days: {} };
    }

    if (!scheduleTable[weekNumber].days[day]) {
      scheduleTable[weekNumber].days[day] = [];
    }

    scheduleTable[weekNumber].days[day].push({ date: schedule.date, schedule });
  });

  // Order days from Monday to Sunday
  const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  // Sort weeks in descending order
  const sortedWeeks = Object.keys(scheduleTable).sort((a, b) => b - a);

  // State to manage the modal
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // Function to handle button click and set modal content
  const handleButtonClick = (notes) => {
    setModalContent(notes);
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      {Object.keys(scheduleTable).length === 0 ? (
        <Box textAlign="center" mt={2}>
          <Typography variant="h6">No schedules available</Typography>
        </Box>
      ) : 
      <TableContainer sx={{
        border: '1px solid #515151', borderRadius: '20px', backgroundColor: theme.palette.background.paper, height: '60vh', overflow: 'auto',
      }}

      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ position: 'sticky', top: 0, left: 0, zIndex: 1, }}>Week No.</TableCell>
              {allDays.map((day) => (
                <TableCell key={day} style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                  {day.slice(0, 3)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedWeeks.map((weekNumber) => (
              <TableRow key={weekNumber}>
                <TableCell style={{
                  position: 'sticky', left: 0, zIndex: 1,
                  backgroundColor: theme.palette.background.paper,

                }}>{weekNumber}</TableCell>
                {allDays.map((day) => (
                  <TableCell key={day} style={{ padding: "5px", border: '1px solid #515151' }}>
                    {scheduleTable[weekNumber].days[day] &&
                      scheduleTable[weekNumber].days[day].map(({ date, schedule }) => (
                        <div key={schedule.id} style={{ padding: "5px", borderRadius: "15px", backgroundColor: theme.palette.card.bg, maxWidth: "max-content", margin: "5px auto" }}>
                          <div style={{ padding: "5px", borderRadius: "15px", backgroundColor: theme.palette.primary.main, color: theme.palette.text.secondary, textAlign: "center" }}>
                            {new Date(date).toLocaleDateString()}</div>
                          <div style={{ padding: "5px", width: "max-content", textAlign: "center", margin: "auto" }}>
                            {`${schedule.startTime} - ${schedule.endTime}`}
                          </div>
                          <div style={{ padding: "0 5px", borderRadius: "5px", textAlign: "center" }}>
                            {`Total Hours: ${schedule.totalHours}`}
                          </div>
                          {schedule.notes && (
                            <div style={{ textAlign: "center" }}>
                              <Button
                                variant='contained'
                                sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.secondary, borderRadius: "10px" }}
                                size="small" onClick={() => handleButtonClick(schedule.notes)}>
                                Notes
                              </Button>
                            </div>
                          )}
                        </div>
                      ))}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }

      {/* Modal to show notes */}
      <Modal open={openModal} onClose={handleCloseModal} >
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400,
          backgroundColor: theme.palette.dialog.paper, border: '2px solid #000',
          color: theme.palette.text.secondary,
          boxShadow: 24,
          p: 4, borderRadius: "10px"
        }}>
          <Typography variant="h6">Notes</Typography>
          <Typography>{modalContent}</Typography>
        </Box>
      </Modal>


    </div>
  );
};

export default EmployeeScheduleTable;

